import { useCallback, useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { getTimeDiffJson } from '../utils/get-time-from-timestamp';
import { SocketStatus } from '../typings';

const useSocket = (
  getSocket: () => Socket,
  onReconnect?: (lastUpdatedTimestamp: number) => void
) => {
  const [socket, setSocket] = useState(getSocket());
  const socketName = ((socket as any).nsp! ?? '').substring(1);

  const isConnectedRef = useRef(false);
  const lastUpdatedTimestampRef = useRef(new Date().getTime());
  const onReconnectRef = useRef(onReconnect);

  onReconnectRef.current = onReconnect;

  const addListener = useCallback(
    (eventName: string, listener: () => void) => {
      socket.on(eventName, listener);
    },
    [socket]
  );

  const removeListener = useCallback(
    (eventName: string, listener: () => void) => {
      socket.off(eventName, listener);
    },
    [socket]
  );

  useEffect(() => {
    setSocket(getSocket());
  }, [getSocket, setSocket]);

  useEffect(() => {
    const onConnect = () => {
      updateSocketStatus();
      isConnectedRef.current = true;
    };

    const onDisconnect = (reason: Socket.DisconnectReason) => {
      if (window.socketStatus) {
        window.socketStatus[socketName] = {
          status: SocketStatus.NOT_CONNECTED,
          message: reason || 'Unknown reason',
        };
      } else {
        window.socketStatus = {
          [socketName]: {
            status: SocketStatus.NOT_CONNECTED,
            message: reason || 'Unknown reason',
          },
        };
      }

      if (navigator.onLine) {
        socket.connect();
        return;
      }
      isConnectedRef.current = false;
      lastUpdatedTimestampRef.current = new Date().getTime();
      socket.disconnect();
    };

    socket.connect();
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.disconnect();
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [socket]);

  const updateSocketStatus = () => {
    if (socket.connected) {
      if (window.socketStatus) {
        window.socketStatus[socketName] = {
          status: SocketStatus.CONNECTED,
          message: 'Connected successfully',
        };
      } else {
        window.socketStatus = {
          [socketName]: {
            status: SocketStatus.CONNECTED,
            message: 'Connected successfully',
          },
        };
      }
    }
  };
  useEffect(() => {
    updateSocketStatus();
  }, [socket.connected]);

  useEffect(() => {
    const reConnect = () => {
      if (isConnectedRef.current && socket.connected) {
        updateSocketStatus();
        return;
      }
      const lastUpdatedTime = lastUpdatedTimestampRef.current;
      if (lastUpdatedTime) {
        const { elapsedHours } = getTimeDiffJson(lastUpdatedTime);

        if (elapsedHours > 6) {
          window.location.reload();
          return;
        }
      }

      socket.disconnect();
      socket.connect();
      onReconnectRef.current?.(lastUpdatedTimestampRef.current);
    };

    const handleOnVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        reConnect();
      }
    };

    window.addEventListener('focus', reConnect);
    window.addEventListener('online', reConnect);
    window.addEventListener('visibilitychange', handleOnVisibilityChange);

    return () => {
      window.removeEventListener('focus', reConnect);
      window.removeEventListener('online', reConnect);
      window.removeEventListener('visibilitychange', handleOnVisibilityChange);
    };
  }, [socket, socket.connected, socketName]);

  return { socket, addListener, removeListener };
};

export default useSocket;
