import ChatCache, { sendMessageToCache } from './cache';
import {
  AttachmentTypes,
  ChannelAccessUpdate,
  CustomerChatMeta,
  EligibleMessageTypes,
  GroupChatMeta,
  GroupLevelAccess,
} from '../typings';
import {
  ChatRowData,
  ChatType,
  SearchChat,
  SearchChatMessage,
  SearchChatPagination,
} from '../components/chat-list-section/chat-row/typings';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import {
  BroadcastFrequencyCappingSettings,
  Message,
  MessageTypesEnum,
} from '../typings/message-types';
import {
  createPaginatedRequestAtom,
  createRequestAtom,
} from '../../../../shared/utils/request-atom';
import { selectedChatIdAtom } from './selected-chat';
import {
  CHAT_UPDATE_TYPE,
  ChannelMembersInfo,
  GroupMessageStatus,
  MessageStatus,
  Param,
  SENDER_TYPE,
  Status,
} from '../typings/chat';
import { SocketUpdates } from '../../../../shared/typings/socket-updates';
import { QuickReply } from '../../../../shared/typings/template';
import { selectedChatIdsListAtom } from './chat-list';

export const eligibleChatMessageFamily = atomFamily(
  () => {
    return atom<Array<EligibleMessageTypes>>([EligibleMessageTypes.NONE]);
  },
  (a: Param, b: Param) => a.id === b.id
);

export const eligibleChatMessagesSetter = atom(
  null,
  (
    get,
    set,
    payload: { chatId: string; eligibleTypes: Array<EligibleMessageTypes> }
  ) => {
    set(
      eligibleChatMessageFamily({ id: payload.chatId }),
      payload.eligibleTypes
    );
  }
);

export const chatPermissionsFamily = atomFamily(
  () => {
    return atom<{ canWrite: boolean; optIn: boolean; canRead: boolean }>({
      canWrite: false,
      optIn: false,
      canRead: true,
    });
  },
  (a: Param, b: Param) => a.id === b.id
);

export const chatPermissionsSetter = atom(
  null,
  (
    get,
    set,
    payload: {
      chatId: string;
      canWrite: boolean;
      optIn: boolean;
      canRead: boolean;
    }
  ) => {
    set(chatPermissionsFamily({ id: payload.chatId }), {
      canWrite: payload.canWrite,
      optIn: payload.optIn,
      canRead: payload.canRead,
    });
  }
);

export const chatQueueFamily = atomFamily(
  () => {
    return atom<Array<{ id: string; index: number; message: Message }>>([]);
  },
  (a: Param, b: Param) => a.id === b.id
);

const getCountFromStatus = (
  status: MessageStatus['status'],
  errorMessage?: string
) => {
  if (status === Status.DELIVERED) {
    return { deliveryCount: 1 };
  }
  if (status === Status.READ) {
    return { readCount: 1 };
  }
  if (status === Status.FAILED) {
    return { erroredCount: 1, errorMessage };
  }

  return {};
};

export const chatMessageStatusSetter = atom(
  null,
  (
    get,
    set,
    { chatId, messageId, status, senderType, errorMessage }: MessageStatus
  ) => {
    if (senderType === SENDER_TYPE.USER) {
      set(sendMessageToCache, {
        chatId,
        partial: true,
        message: {
          id: messageId,
          ...getCountFromStatus(status, errorMessage),
        },
      });
    }
  }
);

export const groupMessageStatusSetter = atom(
  null,
  (
    get,
    set,
    { chatId, chatMessageId: messageId, orgId, ...counts }: GroupMessageStatus
  ) => {
    set(sendMessageToCache, {
      chatId,
      partial: true,
      message: {
        id: messageId,
        ...counts,
      },
    });
  }
);

export const assignedToFamily = atomFamily(
  () => {
    return atom<string>('');
  },
  (a: Param, b: Param) => a.id === b.id
);

export const chatIdsAtom = createPaginatedRequestAtom<
  string,
  SearchChatPagination
>({ loading: false });

export const forwardChatIdsAtom = createPaginatedRequestAtom<
  string,
  SearchChatPagination
>({ loading: false });

export const searchMessagesAtom = atom<SearchChatMessage[]>([]);

export const searchDataFamily = atomFamily(
  () => {
    return atom<SearchChat | SearchChatMessage | undefined>(undefined);
  },
  (a: Param, b: Param) => a.id === b.id
);

export const chatListFamily = atomFamily(
  () => {
    return atom<ChatRowData | undefined>(undefined);
  },
  (a: Param, b: Param) => a.id === b.id
);

interface PhoneDetails {
  chatId: string;
  integrationWabaNumber: string;
  integrationId: string;
}

export const phoneDetailsAtom = atom<Map<string, PhoneDetails>>(new Map());

export const phoneDetailsSetter = atom(
  null,
  (
    get,
    set,
    payload: {
      phoneNumber: string;
      chatId: string;
      integrationId: string;
      integrationWabaNumber?: string;
    }
  ) => {
    const phoneDetails = get(phoneDetailsAtom);
    const tmpMap = new Map(phoneDetails);
    tmpMap.set(payload.phoneNumber + '::' + payload.integrationWabaNumber, {
      chatId: payload.chatId,
      integrationWabaNumber: payload.integrationWabaNumber ?? '',
      integrationId: payload.integrationId,
    });
    set(phoneDetailsAtom, tmpMap);
  }
);

export const chatIdToChatTypeIdMap = atom((get) => {
  const chatIds = get(chatIdsAtom);

  if (!chatIds.data) {
    return {};
  }

  return chatIds.data.reduce(
    (acc, chatId) => {
      const chat = get(chatListFamily({ id: chatId }));
      if (chat) {
        acc[chatId] = chat.chatTypeId;
      }
      return acc;
    },
    {} as {
      [key: string]: string;
    }
  );
});

export const formatedSelectedChatName = atom((get) => {
  // return name
  const chatIds = get(selectedChatIdsListAtom);

  if (!chatIds) {
    return [];
  }

  return chatIds.reduce((acc, chatId) => {
    const chat = get(chatListFamily({ id: chatId }));
    if (chat) {
      acc.push(chat.name);
    }
    return acc;
  }, [] as string[]);
});

export const formatedSelectedChatConfig = atom((get) => {
  // return phonenumber, chatID, chatTypeId
  const chatIds = get(selectedChatIdsListAtom);

  if (!chatIds) {
    return [];
  }

  return chatIds.reduce(
    (acc, chatId) => {
      const chat = get(chatListFamily({ id: chatId }));
      if (chat) {
        acc.push({
          phoneNumber: chat.phoneNumber,
          chatTypeId: chat.chatTypeId,
          chatId: chatId,
        });
      }
      return acc;
    },
    [] as {
      chatId: string;
      phoneNumber: string;
      chatTypeId: string;
    }[]
  );
});

export type ChatListSetterArgs =
  | {
      data: Array<ChatRowData>;
      type: CHAT_UPDATE_TYPE.ADD | CHAT_UPDATE_TYPE.UPDATE;
    }
  | {
      data: Partial<ChatRowData> & Pick<ChatRowData, 'id'>;
      type: CHAT_UPDATE_TYPE.PARTIAL_UPDATE;
    }
  | {
      data: Array<ChatRowData>;
      type: CHAT_UPDATE_TYPE.FORWARD_MSG_UPDATE;
    }
  | {
      updates: SocketUpdates;
      type: CHAT_UPDATE_TYPE.API_UPDATES;
    }
  | { type: CHAT_UPDATE_TYPE.DELETE }
  | {
      type: CHAT_UPDATE_TYPE.ACCESS_UPDATES;
      data: {
        updates: ChannelAccessUpdate[];
        currentUserId?: string;
        addNotification?: (message: string) => void;
      };
    };

export const chatListSetter = atom(
  null,
  (get, set, args: ChatListSetterArgs) => {
    const { type } = args;

    switch (type) {
      case CHAT_UPDATE_TYPE.ADD: {
        const chatIds = args.data.map(({ id, ...chatRow }) => {
          set(chatListFamily({ id }), { id, ...chatRow });
          return id;
        });

        set(chatIdsAtom, (prev) => ({
          ...prev,
          data: chatIds,
        }));
        break;
      }

      case CHAT_UPDATE_TYPE.PARTIAL_UPDATE: {
        const chatListAtom = chatListFamily({ id: args.data.id });
        const chatListData = get(chatListAtom);

        if (!chatListData) {
          return;
        }

        set(chatListAtom, {
          ...chatListData,
          ...args.data,
        });

        set(chatIdsAtom, (prev) => {
          const oldIds = prev.data || [];
          const oldIdsSet = new Set(oldIds);
          oldIdsSet.delete(args.data.id);
          return {
            ...prev,
            data: [args.data.id, ...Array.from(oldIdsSet)],
          };
        });
        break;
      }

      case CHAT_UPDATE_TYPE.UPDATE: {
        const sortedChatList = args.data.sort((a, b) => {
          return (
            (b.lastMessage?.messageTime || 0) -
            (a.lastMessage?.messageTime || 0)
          );
        });

        const ids = sortedChatList.map(({ id, ...chatRow }) => {
          set(chatListFamily({ id }), { id, ...chatRow });
          return id;
        });

        set(chatIdsAtom, (prev) => {
          const oldIds = prev.data || [];
          const oldIdsSet = new Set(oldIds);

          ids?.forEach((id) => oldIdsSet.delete(id));

          return {
            ...prev,
            data: [...ids, ...Array.from(oldIdsSet)],
          };
        });
        break;
      }
      case CHAT_UPDATE_TYPE.FORWARD_MSG_UPDATE: {
        const sortedChatList = args.data.sort((a, b) => {
          return (
            (b.lastMessage?.messageTime || 0) -
            (a.lastMessage?.messageTime || 0)
          );
        });

        const ids = sortedChatList.map(({ id, ...chatRow }) => {
          set(chatListFamily({ id }), { id, ...chatRow });
          return id;
        });

        set(chatIdsAtom, (prev) => {
          const oldIds = prev.data || [];
          const oldIdsSet = new Set(oldIds);

          ids?.forEach((id) => oldIdsSet.delete(id));

          return {
            ...prev,
            forwardData: [...ids, ...Array.from(oldIdsSet)],
          };
        });
        break;
      }
      case CHAT_UPDATE_TYPE.API_UPDATES: {
        const { chatsChanged, chatsRemoved, chatsDeleted } = args.updates;
        const sortedChangedChatList = chatsChanged.sort((a, b) => {
          return (
            (b.lastMessage?.messageTime || 0) -
            (a.lastMessage?.messageTime || 0)
          );
        });

        const chatMessagesCache = ChatCache.getInstance(get, set);

        const changedChatIds = sortedChangedChatList.map(
          ({ messages, ...chat }) => {
            const { id } = chat;
            const prevData = get(chatListFamily({ id }));
            set(
              chatListFamily({ id }),
              prevData
                ? {
                    ...prevData,
                    ...chat,
                  }
                : chat
            );

            chatMessagesCache.setChat(id, messages);
            return id;
          }
        );

        const deletedChatIds =
          chatsRemoved?.map((chat) => {
            const { id } = chat;
            set(chatListFamily({ id })); // removing saved chat data;
            // chatMessagesCache.deleteChat(id, null); //TODO: remove messages for cache to avoid memory leak
            return id;
          }) ?? [];

        set(chatIdsAtom, (prev) => {
          const oldIds = prev.data || [];
          const oldIdsSet = new Set(oldIds);

          changedChatIds?.forEach((id) => oldIdsSet.delete(id));
          deletedChatIds?.forEach((id) => oldIdsSet.delete(id));
          chatsDeleted?.forEach((id) => oldIdsSet.delete(id));

          return {
            ...prev,
            data: [...changedChatIds, ...Array.from(oldIdsSet)],
          };
        });

        break;
      }

      case CHAT_UPDATE_TYPE.DELETE: {
        chatListFamily.setShouldRemove(() => true);
        chatListFamily.setShouldRemove(null);

        set(chatIdsAtom, {
          loading: false,
          isFetchingMore: false,
          data: undefined,
          paginationData: undefined,
        });
        break;
      }

      case CHAT_UPDATE_TYPE.ACCESS_UPDATES: {
        const chatTypeToChatIdsMap = get(chatIdToChatTypeIdMap);
        const id = get(selectedChatIdAtom);

        let selectedChatAction: 'UPDATED' | 'REMOVED' | null = null;

        const { updates, addNotification } = args.data;
        updates.forEach(({ groupId, accessLevel }) => {
          const chatId = chatTypeToChatIdsMap[groupId] ?? '';
          const chatAtom = chatListFamily({ id: chatId });

          if (chatAtom) {
            const chatRow = get(chatAtom);

            if (!chatRow) {
              return;
            }

            if (accessLevel === GroupLevelAccess.NO_ACCESS) {
              if (chatId === id) {
                selectedChatAction = 'REMOVED';
              }

              set(chatRowSetter, { type: CHAT_UPDATE_TYPE.DELETE, id: chatId });
            }

            if (accessLevel && chatRow.groupAccessLevel !== accessLevel) {
              if (chatId === id) {
                selectedChatAction = 'UPDATED';
              }

              set(chatRowSetter, {
                type: CHAT_UPDATE_TYPE.UPDATE,
                partial: true,
                data: {
                  id: chatId,
                  groupAccessLevel: accessLevel,
                },
              });
            }
          }
        });

        if (selectedChatAction === 'REMOVED' && addNotification) {
          addNotification('Access revoked!!');
        }

        if (selectedChatAction === 'UPDATED' && addNotification) {
          addNotification('Access changed!!');
        }
        break;
      }

      default:
        break;
    }
  }
);

export type ChatRowSetterArgs =
  | {
      data: ChatRowData;
      reOrder?: boolean;
      type: CHAT_UPDATE_TYPE.ADD;
    }
  | {
      partial: true;
      reOrder?: boolean;
      skipNew?: boolean;
      data: Partial<ChatRowData> & { id: string };
      type: CHAT_UPDATE_TYPE.UPDATE;
    }
  | {
      partial: false;
      reOrder?: boolean;
      data: ChatRowData;
      type: CHAT_UPDATE_TYPE.UPDATE;
      skipNew?: boolean;
      useOldValue?: boolean;
    }
  | { type: CHAT_UPDATE_TYPE.DELETE; id: string; keepData?: boolean };

export const chatRowSetter = atom(null, (get, set, args: ChatRowSetterArgs) => {
  switch (args.type) {
    case CHAT_UPDATE_TYPE.ADD: {
      const { id } = args.data;
      set(chatListFamily({ id }), args.data);

      if (args.reOrder) {
        set(chatIdsAtom, (prev) => {
          const oldIds = prev.data || [];
          const idsSet = new Set(oldIds);

          const chatExists = idsSet.has(id);

          if (chatExists) {
            return {
              ...prev,
            };
          }

          idsSet.delete(id);

          return {
            ...prev,
            data: [id, ...Array.from(idsSet)],
          };
        });
      }

      break;
    }

    case CHAT_UPDATE_TYPE.UPDATE: {
      const { skipNew } = args;
      const { id, ...chatRow } = args.data;

      const oldChat = get(chatListFamily({ id }));
      if (args.partial && oldChat) {
        set(chatListFamily({ id }), {
          ...oldChat,
          ...chatRow,
        });
      }

      if (!args.partial) {
        set(chatListFamily({ id }), {
          ...(args.useOldValue ? oldChat : {}),
          ...args.data,
        });
      }

      const { reOrder = true } = args;
      if (!reOrder) {
        return;
      }

      set(chatIdsAtom, (prev) => {
        const oldIds = prev.data || [];
        const idsSet = new Set(oldIds);

        const chatExits = idsSet.has(id);

        // will skip new chats to be added in the list if chat does not exist
        if (skipNew && !chatExits) {
          return {
            ...prev,
          };
        }

        idsSet.delete(id);

        return {
          ...prev,
          data: [id, ...Array.from(idsSet)],
        };
      });
      break;
    }

    case CHAT_UPDATE_TYPE.DELETE: {
      if (!args.keepData) {
        chatListFamily.remove({ id: args.id });
      }

      set(chatIdsAtom, (prev) => {
        const oldIds = prev.data || [];
        const idsSet = new Set(oldIds);

        idsSet.delete(args.id);

        return {
          ...prev,
          data: Array.from(idsSet),
        };
      });
      break;
    }

    default:
      break;
  }
});

export const chatDeeplinkSetter = atom(
  null,
  (
    get,
    set,
    args:
      | { type: ChatType.INDIVIDUAL; data: CustomerChatMeta; phone: string }
      | { type: ChatType.GROUP; data: GroupChatMeta }
  ) => {
    if (args.type === ChatType.INDIVIDUAL) {
      const {
        customerName,
        assignedUserId,
        assignedUserName,
        customerChatId,
        customerId,
        tags,
        integrationId,
        integrationWabaNumber,
        integrationWabaPhoneName,
        integrationDisplayName,
        isDone,
      } = args.data;

      const chatRow = get(chatListFamily({ id: customerChatId }));

      if (!chatRow) {
        set(chatListFamily({ id: customerChatId }), {
          assignedUserId,
          chatType: ChatType.INDIVIDUAL,
          chatTypeId: customerId,
          id: customerChatId,
          assignedUserName,
          phoneNumber: args.phone,
          imageUrl: '',
          tags,
          name: customerName,
          unreadCount: 0,
          integrationId,
          integrationWabaNumber,
          integrationWabaPhoneName,
          integrationDisplayName,
          isSlaActive: false,
          slaDueDate: '',
          isDone,
        });
      }

      return;
    }

    const { groupChatId, groupChatName, groupId, ...restGroupData } = args.data;

    const chatRow = get(chatListFamily({ id: groupChatId }));

    if (!chatRow) {
      set(chatListFamily({ id: groupChatId }), {
        ...restGroupData,
        id: groupChatId,
        name: groupChatName,
        chatTypeId: groupId,
        chatType: ChatType.GROUP,
        assignedUserId: null,
        assignedUserName: null,
        phoneNumber: '',
        imageUrl: '',
        tags: [],
        unreadCount: 0,
        integrationId: '',
        integrationWabaNumber: '',
        integrationWabaPhoneName: '',
        integrationDisplayName: '',
        isSlaActive: false,
        slaDueDate: '',
      });
    }

    return;
  }
);

export const attachmentTypeAtom = atom<AttachmentTypes>(AttachmentTypes.MEDIA);

export interface SelectedFile {
  id: string;
  type: AttachmentTypes;
  file: File;
}
export interface SelectedFileWithURL {
  id: string;
  fileURL: string;
  fileName: string;
  fileSize?: number;
  fileType: string;
  messageType: MessageTypesEnum;
}
export type SelectedFileForSendUseCase = SelectedFile | SelectedFileWithURL;

export const selectedFilesAtom = atom<SelectedFile[]>([]);

export const quickRepliesAtom = createRequestAtom<QuickReply[]>();

export const channelMembersInfoAtomFamily = atomFamily(
  (groudId) => {
    return atom<ChannelMembersInfo | null>(null);
  },
  (a: { groupId: string }, b: { groupId: string }) => a.groupId === b.groupId
);

export const channelMembersInfoSetter = atom(
  null,
  (
    get,
    set,
    payload: {
      groupId: string;
      memberCount: number;
      showMemberCount: boolean;
    }
  ) => {
    set(channelMembersInfoAtomFamily({ groupId: payload.groupId }), {
      memberCount: payload.memberCount,
      showMemberCount: payload.showMemberCount,
    });
  }
);

export const broadcastFrequencyCappingSettingsMapAtom = atom<
  Map<string, BroadcastFrequencyCappingSettings>
>(new Map());
